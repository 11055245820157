<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.chief_crew"
                dense
                :disabled="readOnly"
                :loading="isLoading"
                :items="onlyCrew"
                item-value="id"
                item-text="name"
                :label="$t('crew_chief_*')"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.driver"
                dense
                :disabled="readOnly"
                :loading="isLoading"
                :items="onlyDriver"
                item-value="id"
                item-text="name"
                :label="$t('driver_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {},
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      //
      staffTypeOptions: [
        {
          id: "chief_crew",
          name: "Crew",
        },
        {
          id: "driver",
          name: "Driver",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "drivingStaff/getLoading",
      drivingStaff: "drivingStaff/getList",
      //
    }),
    // tenantOEM() {
    //   return this.tenantsList.filter((r) => r.product == "oem");
    // },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "assign";
      } else {
        return "";
      }
    },

    onlyCrew() {
      const arr = this.drivingStaff.filter(
        (r) => r.staff_type === "chief_crew"
      );
      return arr || [];
    },
    onlyDriver() {
      const arr = this.drivingStaff.filter((r) => r.staff_type === "driver");
      return arr || [];
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
  },
  async mounted() {
    if (this.type == "create") {
      if (!this.authUser.isAdmin) {
        // this.options.tenant_id = this.authUser.tenant_id;
        // this.fetchDependents(this.authUser.tenant_id);
      }
      // this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      if (this.item.fleet_staff_assignment.length > 0) {
        const arr = this.item.fleet_staff_assignment;
        this.options.chief_crew = arr.find(
          (r) => r.staff_type === "chief_crew"
        )?.fleet_staff;
        this.options.driver = arr.find(
          (r) => r.staff_type === "driver"
        )?.fleet_staff;
      }
      // this.options.driver = 28;
      this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        const { vehicle_id, date, tenant_id } = this.item;
        const { driver, chief_crew } = this.options;
        const params = {
          vehicle: vehicle_id,
          date,
          tenant_id,
          fleet_staff: { driver, chief_crew },
        };
        this.$emit("onsave", params);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    //
    async fetchDependents(val) {
      const params = {
        tenant_id: val,
      };
      await this.$store.dispatch("drivingStaff/list", params);
    },
  },
};
</script>
<style lang="sass" scoped>
.connectorsRow

  display: flex
  gap:1rem
  .field
    min-width:150px !important
    max-width:165px !important


.deleteButton
  cursor: pointer
  .deleteIcon
    &:hover
      color: red
</style>
